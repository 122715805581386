import React from "react";
import styled from "styled-components";

import Logo from "../images/MortysJoint_light.png";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  background: black;
  padding-bottom: 30px;

  height: 100%;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Image = styled.img`
  width: 90%;
  display: block;

  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  background-color: none;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
    margin-top: 10%;
    width: 60%;
  }
  @media only screen and (min-width: 1024px) {
    margin-top: 5%;
    width: 50%;
  }
  @media only screen and (min-width: 1600px) {
    margin-top: 5%;
    width: 40%;
  }
`;

const Text = styled.div`
  display: block;

  margin-left: auto;
  margin-right: 10px;

  width: 80%;
  max-width: 90vw;
  max-height: 50%;
  text-align: right;
  font-family: "FuturaPT", "monospace";
  font-size: 110%;
  text-transform: uppercase;
  color: #f3efe4;
  @media only screen and (min-width: 480px) {
    font-size: 140%;
  }
  @media only screen and (min-width: 768px) {
    font-size: 220%;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 250%;
    margin-right: 10%;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 300%;
  }
`;

const Header = () => {
  return (
    <Container>
      <a href="/">
        <Image src={Logo} />
        <Text>
          <b>fried chicken & burgers</b>
        </Text>
      </a>
    </Container>
  );
};

export default Header;
