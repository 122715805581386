import React from "react";
import styled from "styled-components";

import Logo from "../images/MJ.png";

const Container = styled.div`
  display: block;

  margin-left: auto;
  margin-right: auto;
  background: #f3efe4;
`;

const Title = styled.div`
  text-transform: uppercase;
  margin: 0;
  font-size: 70px;
  font-family: "FuturaPT", "monospace";
  padding-top: 20px;
  margin-left: 10px;
  color: #ffab0d;
`;

const Day = styled.div`
  text-transform: uppercase;
  font-size: 70px;
  transform: rotate(270deg);
  font-family: "FuturaPT", "monospace";

  width: 20px;
  margin-top: 20px;
`;

const Subtitle = styled.div`
  text-transform: uppercase;
  padding-top: 50px;
  font-size: 50px;
  font-family: "FuturaPT", "monospace";
  margin-left: 70px;
`;

const SubtitleText = styled.div`
  text-transform: uppercase;
  margin: 0;
  font-size: 25px;
  font-family: "FuturaPT", "monospace";
  margin-left: 70px;
  @media only screen and (min-width: 480px) {
    font-size: 30px;
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Text = styled.div`
  padding: 0 5px;
  text-align: left;
  font-size: 20px;
  margin-left: 70px;
`;

const MidSection = styled.div`
  background-color: #ffab0d;
  display: block;

  margin-left: auto;
  margin-right: auto;
  width: 90%;
  height: 100%;
  @media only screen and (min-width: 480px) {
    width: 400px;
  }
  @media only screen and (min-width: 768px) {
    width: 400px;
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Price = styled.div`
  text-transform: uppercase;
  display: inline;
  margin: 0;
  font-size: 25px;
  font-family: "FuturaPT", "monospace";
  margin-right: 20px;
  float: right;
  @media only screen and (min-width: 480px) {
    font-size: 30px;
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Image = styled.img`
  width: 25%;
  display: block;

  margin-left: auto;
  margin-right: 20px;
  margin-top: 20px;
  background-color: none;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const HappyHour = () => {
  return (
    <Container>
      <Title>happy hour</Title>
      <MidSection>
        <Subtitle>3pm - 5pm</Subtitle>
        <SubtitleText>Philter XPA <Price>7</Price></SubtitleText>        
        <SubtitleText>House Wine<Price>7</Price></SubtitleText>
        <SubtitleText>aperol spritz<Price>10</Price></SubtitleText>
        <SubtitleText>frozen marg<Price>12</Price></SubtitleText>
        <Day>fri&nbsp;&&nbsp;sat</Day>
      </MidSection>
      <Image src={Logo} />
    </Container>
  );
};

export default HappyHour;
