import React from "react";
import styled from "styled-components";
import "../App.css";

// import Header from "../components/header";
// import Nav from "../components/nav";
// import Burger from "../components/burger";

// import {snacks} from "../data/snacks";
// import small from "../data/small";
// import large from "../data/large";
// import sides from "../data/sides";

const Container = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;

  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 500px;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const MenuSection = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
`;

const MenuItem = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;
const TopSection = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
`;
const SubSection = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
`;

const Title = styled.h1`
  text-transform: uppercase;
  margin: 0;
  font-size: 100px;
  font-family: "FuturaPT", "monospace";
  padding-top: 20px;
  margin-left: 10px;
`;

const SubTitle = styled.h2`
  text-transform: uppercase;
  font-family: "FuturaPT", "monospace";
  text-align: left;
  font-size: 40px;
  margin: 10px 0;
`;

const AltSubTitle = styled.h2`
  text-transform: uppercase;
  font-family: "FuturaPT", "monospace";
  text-align: center;
  font-size: 40px;
  margin: 10px 0;
`;

const SubText = styled.div`
  padding: 0 5px;
  text-align: left;
`;

const ItemTitle = styled.div`
  text-transform: uppercase;
  font-family: "FuturaPT", "monospace";
  font-size: 1.2em;
  padding: 0 5px;
  @media only screen and (min-width: 768px) {
  }
`;

const ItemRight = styled.div`
  text-transform: uppercase;
  font-family: "FuturaPT", "monospace";
  font-size: 1.2em;
  float: right;
  padding: 0 5px;
  margin-left: 20%;
  @media only screen and (min-width: 768px) {
  }
`;

const ItemDesc = styled.div`
  padding: 0 5px;
  text-align: left;
`;

const Price = styled.div`
  float: right;
  text-align: right;
  font-family: "FuturaPT", "monospace";
  padding: 0 5px;
  @media only screen and (min-width: 768px) {
  }
`;

const Line = styled.hr`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: black;
  height: 2px;
  border: 0 none;
`;

const Text = styled.div`
  padding: 0 5px;
  text-align: center;
  font-size: 20px;
`;

const FootLinkAlt = styled.a`
  text-decoration: none;
  color: black;
  font-family: "Inconsolata", "monospace";
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
    font-size: 25px;
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Index = () => {
  return (
    <Container>
      <Title>eat</Title>
      <Text>Our entire menu is available take-away, call <FootLinkAlt id="phone" href="tel:+61439375894">
          0439 375 894
        </FootLinkAlt> to order, or swing by.</Text>
      <MenuSection>
        <SubTitle>Snacks</SubTitle>
        <Line />
        <MenuItem>
          <TopSection>
            <ItemTitle>charred corn 'ribs'</ItemTitle>
            <Price>9</Price>
          </TopSection>
          <ItemDesc>chilli & lime salt, cheese sauce, herbs (gf, vegan)</ItemDesc>
        </MenuItem>
        <Line />
        <MenuItem>
          <TopSection>
            <ItemTitle>loaded fries</ItemTitle>
            <Price>13</Price>
          </TopSection>
          <ItemDesc>tomato, jalapeno, mixed herbs, cheese (gf, vegan)</ItemDesc>
        </MenuItem>
        <Line />
        <SubSection>
          <SubTitle>chicken</SubTitle>
          <SubText>free range, always</SubText>
        </SubSection>
        <Line />
        <MenuItem>
          <TopSection>
            <ItemTitle>popcorn chicken</ItemTitle>
            <Price>15</Price>
          </TopSection>
          <ItemDesc>chilli oil, yuzu mayo or ‘98 szechuan sauce (gf)</ItemDesc>
        </MenuItem>
        <MenuItem>
          <TopSection>
            <ItemTitle>sticky wings</ItemTitle>
            <ItemTitle>5-pce</ItemTitle>
            <Price>16</Price>
          </TopSection>
          <ItemDesc>aromatic soy, mixed herbs, sesame (gf)</ItemDesc>
        </MenuItem>
        <Line />
        <MenuItem>
          <TopSection>
            <ItemTitle>m.f.c</ItemTitle>
            <ItemDesc>morty's fried chicken</ItemDesc>
            <Price>2-PCE 12</Price>
            <Price>3-PCE 18</Price>
          </TopSection>
        </MenuItem>
        <MenuItem>
          <TopSection>
            <ItemRight>+ duck fat chilli oil</ItemRight>
            <Price>2</Price>
          </TopSection>          
        </MenuItem>
        <MenuItem>
          <TopSection>
            <ItemRight>+ add sauce</ItemRight>
            <Price>2</Price>
          </TopSection>          
        </MenuItem>
        <Line />
        <MenuItem>
          <TopSection>
            <ItemTitle>schnitty</ItemTitle>
            <Price>23</Price>
          </TopSection>
          <ItemDesc>panko-crumbed, chicken gravy, fries, mixed leaves</ItemDesc>
        </MenuItem>
        <Line />
        <TopSection>
          <SubTitle>burgers</SubTitle>
          <ItemTitle>+gf/vegan bun</ItemTitle>
            <Price>2</Price>
        </TopSection>
        <Line />
        <MenuItem>
          <TopSection>
            <ItemTitle>cheese</ItemTitle>
            <Price>16</Price>
          </TopSection>
          <ItemDesc>Dorrigo beef, cheese, burger sauce, onion, pickles</ItemDesc>
        </MenuItem>
        <MenuItem>
          <TopSection>
            <ItemRight>+ bacon</ItemRight>
            <Price>2</Price>
          </TopSection>          
          <TopSection>
            <ItemRight>+ double pattie</ItemRight>
            <Price>6</Price>
          </TopSection>          
        </MenuItem>
        <Line />
        <MenuItem>
          <TopSection>
            <ItemTitle>chicken</ItemTitle>
            <Price>18</Price>
          </TopSection>
          <ItemDesc>M.F.C thigh fillet, white slaw, choice of sauce</ItemDesc>
        </MenuItem>
        <MenuItem>
          <TopSection>
            <ItemRight>+ duck fat chilli oil</ItemRight>
            <Price>2</Price>
          </TopSection>          
        </MenuItem>
        <Line />
        <MenuItem>
          <TopSection>
            <ItemTitle>vegetarian</ItemTitle>
            <Price>16</Price>
          </TopSection>
          <ItemDesc>Bellingen tempeh, cheese, burger sauce, onion, pickles, cos lettuce</ItemDesc>
        </MenuItem>
        <Line />
        <SubTitle>sides & sauce</SubTitle>
        <Line />
        <MenuItem>
          <TopSection>
            <ItemTitle>fries</ItemTitle>
            <Price>7</Price>
          </TopSection>
          <ItemDesc>red miso & vinegar, tomato sauce (gf, vegan)</ItemDesc>
        </MenuItem>
        <Line />
        <MenuItem>
          <TopSection>
            <ItemTitle>slaw</ItemTitle>
            <Price>9</Price>
          </TopSection>
          <ItemDesc>cabbage, fennel, daikon, currants, pepitas (gf)</ItemDesc>
        </MenuItem>
        <Line />        
        <MenuItem>
          <TopSection>
            <ItemTitle>sauce</ItemTitle>
            <Price>2</Price>
          </TopSection>
          <ItemDesc>yuzu mayo, '98 szechuan sauce</ItemDesc>
        </MenuItem>
        <Line />
        <AltSubTitle>order at the bar</AltSubTitle>
        <Line />
      </MenuSection>
    </Container>
  );
};

export default Index;
