import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  align-content: center;
  margin-top: 20px;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const FootLink = styled.a`
  text-decoration: none;
  color: black;
  font-family: "FuturaPT", "monospace";
  font-size: 1.4em;
  padding-left: 20px;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
    font-size: 2em;
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;
const FootText = styled.div`
  text-decoration: none;
  color: black;
  font-family: "Inconsolata", "monospace";
  font-size: 1em;
  text-align: center;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
    font-size: 1.7em;
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const FootLinkAlt = styled.a`
  text-decoration: none;
  color: black;
  font-family: "Inconsolata", "monospace";
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
    font-size: 25px;
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Footer = () => {
  return (
    <Container>
      <FootLink
        href="https://g.page/bar-que-sera-sawtell?share"
        target="_blank"
        rel="noopener noreferrer"
      >
        63 First Ave, Sawtell
      </FootLink>
      <FootText>
        <strong>OPEN</strong>
        <br/>
        Wed & Thu 
        <br/>
        5-9:30pm // Kitchen 5-8pm
        <br />
        Fri & Sat 
        <br/>
        3-10pm // Kitchen 3-8:30pm
        <br />
        <br />
        <strong>CLOSED</strong>
        <br/>
        Sun, Mon, Tue
        
      </FootText>
      <FootLinkAlt id="phone" href="tel:+61439375894">
      <br />0439 375 894
        </FootLinkAlt>
    </Container>
  );
};

export default Footer;
