import React, { useEffect } from "react";
import "./App.css";
import "./global.style";
import styled from "styled-components";
import { Routes, Route } from "react-router-dom";
import WebFont from "webfontloader";

import Header from "./components/header";
import NavMobile from "./components/navMobile";
import Nav from "./components/nav";
import Modal from "./components/modal";
import Promo from "./components/promo";

import Index from "./pages/indexPage";
import Menu from "./pages/menuPage";
import Bookings from "./pages/bookingsPage";
import Hours from "./pages/hoursPage";
import WhatsOn from "./pages/whatsOnPage";

var pageWidth = window.screen.availWidth;

function App() {
  useEffect(() => {
    WebFont.load({
      custom: {
        families: ["Futura PT", "Inconsolata"],
        urls: ["/index.css"],
      },
    });
  }, []);
  return (
    <>
    
      <>
        <Header />
        {/* <Modal /> */}
        {/* {pageWidth > 768 ? (<Nav />):(<NavMobile />)} */}
        <NavMobile />
        <Routes>
          <Route exact path="/" element={<Index />} />
          <Route exact path="/menu" element={<Menu />} />
          <Route exact path="/bookings" element={<Bookings />} />
          <Route exact path="/hours" element={<Hours />} />
          <Route exact path="/whatson" element={<WhatsOn />} />
        </Routes>
        <Promo />
      </>
    </>
  );
}

export default App;
