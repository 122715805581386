import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: 100%;
  overflow: hidden;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Text = styled.div`
  margin: 15px 0;
  font-size: 0.8em;
`;

const Promo = () => {
  return (
    <Container>
        <Text>Created and hosted by HolisTechAus &copy;2023</Text>
    </Container>
  );
};

export default Promo;