import React from "react";
import styled from "styled-components";

import Logo from "../images/MJ.png";

const Container = styled.div`
  display: block;

  margin-left: auto;
  margin-right: auto;
  background: #f3efe4;
`;

const Title = styled.div`
  text-transform: uppercase;
  margin: 0;
  font-size: 70px;
  font-family: "FuturaPT", "monospace";
  padding-top: 20px;
  margin-left: 0px;
  color: #ffab0d;
`;

const Day = styled.div`
  text-transform: uppercase;
  font-size: 70px;
  transform: rotate(270deg);
  font-family: "FuturaPT", "monospace";

  width: 20px;
  margin-top: 30px;
`;

const Subtitle = styled.div`
  text-transform: uppercase;
  padding-top: 50px;
  font-size: 50px;
  font-family: "FuturaPT", "monospace";
  margin-left: 70px;
`;

const SubtitleText = styled.div`
  text-transform: uppercase;
  margin: 0;
  font-size: 25px;
  font-family: "FuturaPT", "monospace";
  margin-left: 50px;
`;

const Text = styled.div`
  padding: 0 5px;
  text-align: left;
  font-size: 20px;
  font-family: "Inconsolata", "FuturaPT", -apple-system, BlinkMacSystemFont, "Inconsolata", sans-serif;
  margin-left: 70px;
`;

const MidSection = styled.div`
  background-color: #ffab0d;
  display: block;

  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  width: 100%;
  height: 100%;
  @media only screen and (min-width: 480px) {
    width: 400px;
  }
  @media only screen and (min-width: 768px) {
    width: 400px;
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Price = styled.div`
  text-transform: uppercase;
  display: inline;
  margin: 0;
  font-size: 20px;
  font-family: "Inconsolata", "FuturaPT", -apple-system, BlinkMacSystemFont, "Inconsolata", sans-serif;
  margin-right: 10px;
  float: right;
`;

const Image = styled.img`
  width: 25%;
  display: block;

  margin-left: auto;
  margin-right: 20px;
  margin-top: 20px;
  background-color: none;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const HappyHour = () => {
  return (
    <Container>
      <Title>long weekend</Title>
      <MidSection>
        <SubtitleText>fri, 29th sept <Price>12-9PM</Price></SubtitleText>
        
        <SubtitleText>sat, 30th sept <Price>12-9pm</Price></SubtitleText>
        <SubtitleText>sun, 1st oct<Price>4-8pm</Price></SubtitleText>
        <SubtitleText>mon, 2nd oct <Price>4-8pm</Price></SubtitleText>
        <br/>
        <SubtitleText>fri & sat </SubtitleText>
        <br/>
        <SubtitleText>lunch deal<Price>12-3pm</Price></SubtitleText>
        <Text>Free Mac 'n Cheese</Text>
        <SubtitleText>happy hour <Price>3-5pm</Price></SubtitleText>
        
        
        <Day>what's&nbsp;on</Day>
      </MidSection>
      <Image src={Logo} />
    </Container>
  );
};

export default HappyHour;
