import React from "react";
import styled from "styled-components";
import "../App.css";

import Socials from "../components/socials";
import Footer from "../components/footer";
// import Bookings from "../components/bookings";
import ScrollTopButton from "../components/scrollTopButton";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  height: 100%;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;
const Low = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
`;

const Text = styled.div`
  margin-bottom: 20px;
  font-size: 20px;
  width: 90%;
  font-family: Inconsolata, "monospace";
  text-align: center;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const FootLinkAlt = styled.a`
  text-decoration: none;
  color: black;
  font-family: "Inconsolata", "monospace";
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
    font-size: 25px;
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Index = () => {
  return (
    <Container>
      <ScrollTopButton/>
      <Socials />
      <Low>
        <Footer />
        <Text>
          {" "}
          Check out our sister venue,{" "}
          <FootLinkAlt
            href="https://www.bar-que-sera.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Bar Que Sera
          </FootLinkAlt>
          , a city wine bar in a seaside village.
        </Text>
      </Low>
    </Container>
  );
};

export default Index;
