import React from "react";
import styled from "styled-components";

import facebook from "../images/fb_logo.png";
import insta from "../images/insta_logo.png";

const Container = styled.div`
  display: block;

  margin-left: auto;
  margin-right: auto;
  padding: 30px 20px;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const SocialLink = styled.a`
  display: flex;
  justify-content: center;
  padding: 0 15px;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Icon = styled.img`
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const FootLinkAlt = styled.a`
  text-decoration: none;
  color: black;
  font-family: "Inconsolata", "monospace";
  font-size: 1em;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
    font-size: 1.7em;
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Socials = () => {
  return (
    <Container>
      <FlexContainer>
        <SocialLink
          href="https://www.instagram.com/mortys_joint/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon src={insta} alt="instagram logo"></Icon>
        </SocialLink>
        <SocialLink
          href="https://www.facebook.com/MortysJoint"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon src={facebook} alt="facebook logo"></Icon>
        </SocialLink>
      </FlexContainer>
      {/* <FootLinkAlt href="mailto: hello@bar-que-sera.com">
        hello@mortys-joint.com
      </FootLinkAlt> */}
    </Container>
  );
};

export default Socials;
