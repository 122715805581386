import React from "react";
import styled from "styled-components";
import "../App.css";

import Socials from "../components/socials";
import SchnittyNight from "../components/schnittyNight";
import HappyHour from "../components/happyHour";
import FreeWings from "../components/freeWings";
import LunchCombo from "../components/lunchCombo";
import WeeklySpesh from "../components/weeklySpesh";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: 100%;
  overflow: hidden;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Text = styled.div`
  margin-bottom: 20px;
  font-size: 1em;
  width: 90%;
  font-family: Inconsolata, "monospace";
  text-align: center;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Line = styled.hr`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: black;
  height: 2px;
  border: 0 none;
`;

const SubText = styled.div`
  text-transform: uppercase;
  margin: 0;
  font-size: 30px;
  font-family: "FuturaPT", "monospace";
`;

const WhatsOn = () => {
  return (
    <Container>
      {/* <Title>What's On</Title> */}
      <Line/>
      <SchnittyNight/>
      {/* <WeeklySpesh/> */}
      <HappyHour/>
      <SubText>free pool, always</SubText>
      <Text>(18+ only)</Text>
      <Line/>
    </Container>
  );
};

export default WhatsOn;
