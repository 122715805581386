import React from "react";
import styled from "styled-components";
import "../App.css";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: 100%;
  overflow: hidden;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Text = styled.div`
  margin-bottom: 20px;
  font-size: 20px;
  width: 90%;
  font-family: Inconsolata, "monospace";
  text-align: center;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const FootLinkAlt = styled.a`
  text-decoration: none;
  color: black;
  font-family: "Inconsolata", "monospace";
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
    font-size: 25px;
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Line = styled.hr`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: black;
  height: 2px;
  border: 0 none;
`;

var pageWidth = window.screen.availWidth;

const Hours = () => {
  return (
    <Container>
      <Line />

      <Text>
        Phone or text{" "}
        <FootLinkAlt id="phone" href="tel:+61439375894">
          0439 375 894
        </FootLinkAlt>
      </Text>
      <Text>
        Email{" "}
        <FootLinkAlt href="mailto: hello@mortys-joint.com">
          hello@mortys-joint.com
        </FootLinkAlt>
      </Text>
      <Text>Message on socials</Text>
      <Text>We're a large venue with plenty of walk-in space available. </Text>

      
      <Line />
    </Container>
  );
};

export default Hours;
