import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: "Futura PT";
    font-style: bold;
    font-weight: 400;
    stretch:100;
    src: url("font/FuturaPTBold.otf") format("opentype");
    src: local('?'), url("font/FuturaPTBold.otf") format("opentype");
  }
`;

export default GlobalStyle;
