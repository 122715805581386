import React from "react";
import styled from "styled-components";

import Logo from "../images/MJ.png";

const Container = styled.div`
  display: block;

  margin-left: auto;
  margin-right: auto;
  background: #ffab0d;
`;

const Title = styled.div`
  text-transform: uppercase;
  margin: 0;
  font-size: 70px;
  font-family: "FuturaPT", "monospace";
  padding-top: 20px;
  margin-left: 10px;
  color: #f3efe4;
  @media only screen and (min-width: 425px) {
    width: 60%;
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Day = styled.div`
  text-transform: uppercase;
  font-size: 70px;
  transform: rotate(270deg);
  font-family: "FuturaPT", "monospace";

  width: 20px;
  margin-top: -20px;
`;

const Subtitle = styled.div`
  text-transform: uppercase;
  font-size: 50px;
  font-family: "FuturaPT", "monospace";
  margin-left: 70px;
`;

const HeadSubtitle = styled.div`
  text-transform: uppercase;
  font-size: 50px;
  float: right;
  margin-top: -70px;
  font-family: "FuturaPT", "monospace";
  margin-left: 70px;
  @media only screen and (min-width: 425px) {
    margin-right: 10%;
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const SubtitleText = styled.div`
  text-transform: uppercase;
  margin: 0;
  float: right;
  margin-right: 10px;
  font-size: 45px;
  font-family: "FuturaPT", "monospace";
  margin-left: 70px;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Text = styled.div`
  padding: 0 5px;
  text-align: left;
  font-size: 20px;
  margin-left: 70px;
`;

const MidSection = styled.div`
  background-color: #f3efe4;
  display: block;
  padding-top: 50px;

  margin-left: auto;
  margin-right: auto;
  width: 90%;
  height: 100%;
  @media only screen and (min-width: 480px) {
    width: 400px;
  }
  @media only screen and (min-width: 768px) {
    width: 400px;
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Image = styled.img`
  width: 25%;
  display: block;

  margin-left: auto;
  margin-right: 20px;
  margin-top: 20px;
  background-color: none;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const LunchCombo = () => {
  return (
    <Container>
      <Title>lunch deal</Title>
      <HeadSubtitle>12-3pm</HeadSubtitle>
      <MidSection>        
        <Subtitle>burger,</Subtitle>
        <Subtitle>fries &</Subtitle>
        <Subtitle>drink</Subtitle>
        <SubtitleText>$25</SubtitleText>
        <Text>&nbsp; </Text>
        <Text>Philter XPA</Text>
        <Text>House Wine</Text>
        <Text>Soft Drink</Text>
        <Day>Saturday</Day>
      </MidSection>      
      <Image src={Logo} />
    </Container>
  );
};

export default LunchCombo;
