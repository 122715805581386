import React from "react";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";

const Container = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Item = styled.h1`
  text-transform: uppercase;
  margin: 10px;
  font-size: 40px;
  font-family: "FuturaPT", "monospace";
  display: flex;
  color: #f4efe4;
`;

const Nav = () => {
  return (
    <Container>
      <NavLink
        to="/menu"
        style={({ isActive }) => ({
          color: isActive ? "#fff" : "#545e6f",
          textDecorationLine: isActive ? "underline" : "none",
          fontSize: isActive ? "50px" : "40px",
        })}
      >
        <Item>Menu</Item>
      </NavLink>
      <NavLink
        to="/bookings"
        style={({ isActive }) => ({
          color: isActive ? "#fff" : "#545e6f",
          textDecorationLine: isActive ? "underline" : "none",
          fontSize: isActive ? "50px" : "40px",
        })}
      >
        <Item>Bookings</Item>
      </NavLink>
      {/* <a
        href="https://squareup.com/gift/ML9390RCYVN4E/order"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Item>Takeaway</Item>
      </a> */}
      <NavLink 
      to="/hours"
      style={({ isActive }) => ({
        color: isActive ? "#f4efe4" : "",
        textDecorationLine: isActive ? "underline" : "none",
        fontSize: isActive ? "50px" : "40px",
      })}
      >
        <Item>What's on</Item>
      </NavLink>
    </Container>
  );
};

export default Nav;
