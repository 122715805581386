import React, { useState } from "react";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";

import Menu from "../pages/menuPage";
import Hours from "../pages/hoursPage";
import WhatsOn from "../pages/whatsOnPage";
import Bookings from "../pages/bookingsPage";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Item = styled.h1`
  text-transform: uppercase;
  margin: 10px;
  font-size: 40px;
  font-family: "FuturaPT", "monospace";
  display: flex;
  color: #f4efe4;
`;

const Nav = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showHours, setShowHours] = useState(false);
  const [showWhatsOn, setShowWhatsOn] = useState(false);
  const [showBookings, setShowBookings] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
    setShowHours(false);
    setShowWhatsOn(false);
    setShowBookings(false);
  };
  const toggleHours = () => {
    setShowHours(!showHours);
    setShowMenu(false);
    setShowWhatsOn(false);
    setShowBookings(false);
  };
  const toggleWhatsOn = () => {
    setShowWhatsOn(!showWhatsOn);
    setShowMenu(false);
    setShowHours(false);
    setShowBookings(false);
  };
  const toggleBookings = () => {
    setShowBookings(!showBookings);
    setShowMenu(false);
    setShowHours(false);
    setShowWhatsOn(false);
  };

  return (
    <Container>
      <NavLink
        // to="/menu"
        onClick={toggleMenu}
        // style={({ isActive }) => ({
        //   color: isActive ? "#fff" : "#545e6f",
        //   textDecorationLine: isActive ? "underline" : "none",
        //   fontSize: isActive ? "50px" : "40px",
        // })}
      >
        <Item>Menu</Item>
      </NavLink>
      {showMenu && <Menu />}

      <NavLink
        // to="/whatson"
        onClick={toggleHours}
        // style={({ isActive }) => ({
        //   color: isActive ? "#fff" : "#545e6f",
        //   textDecorationLine: isActive ? "underline" : "none",
        //   fontSize: isActive ? "50px" : "40px",
        // })}
      >
        <Item>Hours</Item>
      </NavLink>
      {showHours && <Hours />}

      <NavLink
        // to="/hours"
        onClick={toggleWhatsOn}
        // style={({ isActive }) => ({
        //   color: isActive ? "#f4efe4" : "",
        //   textDecorationLine: isActive ? "underline" : "none",
        //   fontSize: isActive ? "50px" : "40px",
        // })}
      >
        <Item>What's on</Item>
      </NavLink>
      {showWhatsOn && <WhatsOn />}

      <NavLink
        // to="/bookings"
        onClick={toggleBookings}
        // style={({ isActive }) => ({
        //   color: isActive ? "#fff" : "#545e6f",
        //   textDecorationLine: isActive ? "underline" : "none",
        //   fontSize: isActive ? "50px" : "40px",
        // })}
      >
        <Item>Bookings</Item>
      </NavLink>
      {showBookings && <Bookings />}

      {/* <a
        href="https://squareup.com/gift/ML9390RCYVN4E/order"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Item>Takeaway</Item>
      </a> */}
    </Container>
  );
};

export default Nav;
